<template>

  <div class="gridpraxen mx-4">

    <div v-if="sortedData.length == 0" class="mx-auto my-20">

      <p class="text-center"><i class="fas fa-spin text-blue-500 fa-stethoscope spinner-big"></i> </p>

    </div>

    <div class="">

      <h1 class="mt-8 mb-6 sm:mb-0" v-html="this.data[0].name"> </h1>

      <div class="flex flex-col-reverse sm:flex sm:flex-row">

        <div v-bind:class="{'lg:w-2/3': sortedData.length > 2, 'lg:w-1/2': sortedData.length < 3}" class="w-full mr-8">

          <div class="gridmitarbeitende">

            <div class="flex flex-wrap overflow-hidden m-2">

              <div v-for="index in sortedData" class="p-2 rounded overflow-hidden outline w-full md:w-1/2" v-bind:class="{'lg:w-1/3': sortedData.length > 2, 'lg:w-1/2': sortedData.length < 3}">

                <img v-if="index.portrait" class="w-full" :src="index.portrait.url" alt="Porträt">
                <img v-else class="w-full" src="../assets/portrait_placeholder.png" alt="Porträt Platzhalter">

                <div class="p-4">

                  <h2 class="">{{index.name}} {{index.lastname}}</h2>

                  <p class="text-black text-base"> {{index.funktion}} </p>

                </div>

              </div>

            </div>

          </div>

        </div>

        <div class="w-full md:w-1/2" v-bind:class="{'lg:w-1/3': sortedData.length > 2, 'lg:w-1/2': sortedData.length < 3}">


          <div class="" v-html="this.data[0].philosophy" > </div>


        </div>

      </div>


    </div>


  </div>
</template>

<script>
export default {
  name: 'PraxisDetail',
  props: {

  },
  data() {
    return {
      data: [],
      accordeon: [],
      doctors: [],
      datadoctor: [],
      assistenz: [],
      lernende: [],
      loaded: false,
    }
  },
  methods: {

    fetchContent(){

      fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/praxis?per_page=100&filter[meta_key]=url&filter[meta_value]=' + this.$route.params.url  )  .then((result) => {
        return result.json();
      }).then((data) => {

        var i;
        for (i in data) {
          this.data.push(data[i].acf);

        }

        // console.log("länge " + data[0].acf.arzt.length)

        var j;
        for (j in data[0].acf.arzt) {
          this.doctors.push(data[0].acf.arzt[j].ID);
        }

        this.fetchdoctor();
        // this.fetchassistent();

      });

    },

    fetchdoctor(){

      var k;
      for (k in this.doctors) {

        // hole arzt mit ID [p]
        fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/mitarbeitende?filter[p]=' + this.doctors[k] )  .then((result) => {
          return result.json();
        }).then((data) => {

          this.datadoctor.push(data[0].acf);

          console.log(this.datadoctor[0].portrait.url);
          this.loaded = true;


        });

      }

    },

    compare(a, b) {
      if (a.wichtigkeit < b.wichtigkeit) {
        return -1;
      }
      if (a.wichtigkeit > b.wichtigkeit) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

  },
  mounted: function(){
    this.fetchContent();
  },

  computed: {

    sortedData() {
      // console.log(this.data.slice().sort(this.compare));
      return this.datadoctor.slice().sort(this.compare);
    },

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.spinner-big{
  font-size: 5rem;
  text-align: center !important;
}

table {
    border: 1px solid #CCC;
    border-collapse: collapse;
}

td {
    border: none;
}

@media only screen and (max-width: 550px) {



}

</style>
