<template>
  <div class="home bg-white h-full">

    <Notfall class="z-50" v-if="$store.state.notfall"> </Notfall>

    <div class="body w-full mx-auto bg-white">

      <Navigation> </Navigation>

      <div class="mt-10 mx-auto w-full lg:w-3/4 p-2">

      <h1>Impressum</h1>

      <p>
        Ärztezentrum Fellergut <br>
        Brigitt Luginbühl <br>
        Mühledorfstrasse 1 <br>
        3018 Bern</p>

        <p class="mt-4"> <a class="url hover:underline" href="mailto:praxis.b.luginbuehl[at]hin.ch ?&body=Bitte [at] bei Empfänger durch @ ersetzen."> praxis.b.luginbuehl[at]hin.ch </a> </p>

        <p class="mt-4">  Das Urheber&shyrecht sowie alle anderen Rechte an Texten, Bildern, Fotos oder Dateien auf dieser Web&shyseite gehören dem Ärztezentrum Fellergut.
          Fotos dürfen nicht ohne Ge&shynehmigung der jeweils abgebildeten oder verantwortlichen Person weiter&shyverbreitet werden.</p>

          <p class="mt-4"> Diese Webseite wurde entworfen und erstellt von Nick Schneeberger.</p>

          <p class="mt-2">  <a class="url hover:underline" target="_blank" href="http://www.nickschnee.ch"> nickschnee.ch </a> </p>


          <div class="full-accordion">

            <p @click="openaccordeon(1)" class="accordion bg-blue-200 hover:bg-blue-300" v-bind:class="{ 'bg-blue-300': accordeon.includes(1) }" > Copyright </p>

            <div v-if="accordeon.includes(1)" class="panel">
              <p class="mt-4 pl-4"> Das Copyright für sämtliche Inhalte dieser Website liegt beim Ärztezentrum Fellergut. </p>
            </div>

          </div>

          <div class="full-accordion">

            <p @click="openaccordeon(4)" class="accordion bg-blue-200 hover:bg-blue-300" v-bind:class="{ 'bg-blue-300': accordeon.includes(4) }" > Matomo Webanalyse</p>

            <div v-if="accordeon.includes(4)" class="panel">
              <p class="mt-4 pl-4">Wir machen eine Web&shyanalyse, um Details über die Nutzung unserer Website zu erfahren.</p>

              <p class="mt-4 pl-4">Für die Web&shyanalyse verwenden wir den Open-Source-Dienst <a target="_blank" class="hover:underline" href="http://www.matomo.org">Matomo</a>. Matomo läuft auf unserem <strong> Server in der Schweiz </strong> und nur das Ärztezentrum Fellergut und der Web&shyadmin&shyistrator haben Zugriff auf die anonym&shysiert ge&shysammelten Daten. </p>

              <p class="mt-4 pl-4">Matomo verwendet so&shygenannte Cookies. Das sind Text&shydateien, die auf Ihrem Computer ge&shyspeichert werden und die eine Analyse der Be&shynutzung der Website durch Sie ermöglichen. Dazu werden die durch den Cookie erzeugten In&shyformationen über die Be&shynutzung dieser Web&shyseite auf unserem Server in der Schweiz ge&shyspeichert. Die IP-Adresse wird vor der Speicherung anonym&shyisiert. Die durch den Cookie erzeugten Informationen über die Benutzung dieser Website werden nicht an Dritte weiter&shygegeben.</p>

              <p class="mt-4 pl-4">Wenn Sie mit der Speicherung und Aus&shywertung Ihrer Daten nicht ein&shyverstanden sind, können Sie diese hier de&shyaktivieren.</p>

              <iframe
              style="border: 0; height: 200px; width: 100%;"
              src="http://analytics.nickschnee.ch/index.php?module=CoreAdminHome&action=optOut&language=en&backgroundColor=&fontColor=333333&fontSize=16px&fontFamily=Avenir"
              ></iframe>


            </div>

          </div>

          <div class="full-accordion">

            <p @click="openaccordeon(2)" class="accordion bg-blue-200 hover:bg-blue-300" v-bind:class="{ 'bg-blue-300': accordeon.includes(2) }" > Haftungsausschluss </p>

            <div v-if="accordeon.includes(2)" class="panel">
              <p class="mt-4 pl-4"> Alle Texte und Links wurden sorgfältig geprüft und werden laufend aktualisiert.</p>

              <p class="mt-4 pl-4">Wir sind bemüht, richtige und vollständige Informationen auf dieser Website bereitzustellen, übernehmen aber keinerlei Verantwortung, Garantien oder Haftung dafür, dass die durch diese Website bereitgestellten Informationen, richtig, vollständig oder aktuell sind. Wir behalten uns das Recht vor, jederzeit und ohne Vorankündigung die Informationen auf dieser Website zu ändern und verpflichten uns auch nicht, die enthaltenen Informationen zu aktualisieren.</p>

              <p class="mt-4 pl-4">Alle Links zu externen Anbietern wurden zum Zeitpunkt ihrer Aufnahme auf ihre Richtigkeit überprüft, dennoch haften wir nicht für Inhalte und Verfügbarkeit von Websites, die mittels Hyperlinks zu erreichen sind. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die durch Inhalte verknüpfter Seiten entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde. Dabei ist es gleichgültig, ob der Schaden direkter, indirekter oder finanzieller Natur ist oder ein sonstiger Schaden vorliegt, der sich aus Datenverlust, Nutzungsausfall oder anderen Gründen aller Art ergeben könnte.</p>

              <p class="mt-4 pl-4"> Für die Sicherheit der Datenübertragung im Internet können wir keine Gewähr übernehmen, insbesondere besteht bei der Übertragung von Daten per E-Mail die Gefahr des Zugriffs durch Dritte. </p>

              <p class="mt-4 pl-4">Einer Nutzung der im Impressum veröffentlichten Kontaktdaten durch Dritte zu Werbezwecken wird hiermit ausdrücklich widersprochen. Der Betreiber behält sich für den Fall unverlangt zugesandter Werbe- oder Informationsmaterialien ausdrücklich rechtliche Schritte vor.</p>

              <p class="mt-4 pl-4">Sollten einzelne Regelungen oder Formulierungen dieses Haftungsausschlusses unwirksam sein oder werden, bleiben die übrigen Regelungen in ihrem Inhalt und ihrer Gültigkeit hiervon unberührt.</p>

            </div>

          </div>

        </div>

      <!-- <AktuellesDetail class="mb-8"> </AktuellesDetail> -->

      <Cookie> </Cookie>

      <FooterVue> </FooterVue>

    </div>

  </div>

</template>

<script>
// @ is an alias to /src
import GridPraxen from '@/components/GridPraxen.vue'
import Navigation from '@/components/Navigation.vue'
import Overview from '@/components/Overview.vue'
import AktuellesDetail from '@/components/AktuellesDetail.vue'
import FooterVue from '@/components/FooterVue.vue'
import Cookie from '@/components/Cookie.vue'

export default {
  name: 'Impressum',
  components: {
    GridPraxen,
    Navigation,
    Overview,
    AktuellesDetail,
    FooterVue,
    Cookie
  },

  data() {
    return {
      accordeon: [],

    }
  },

  methods: {

    openaccordeon(index){

          if (this.accordeon.includes(index)){
            var arrayposition = this.accordeon.indexOf(index);
            // console.log(arrayposition);
            if (arrayposition > -1) {
              this.accordeon.splice(arrayposition,1)
            }

          } else {
            this.accordeon.push(index);
            // console.log(this.accordeon);
          }
        },

  },
  mounted: function(){

  },

}
</script>

<style scoped>


.full-accordion{
  margin:20px;
}

.accordion {
  cursor: pointer;
  padding: 15px;
  width: 100%;
  border: 2px solid black;
  text-align: left;
  outline: none;
  transition: 0.4s;
}

@media only screen and (max-width: 550px) {

  .accordion {
    cursor: pointer;
    padding: 8px;
    width: 100%;
    border: 2px solid black;
    text-align: left;
    outline: none;
    transition: 0.4s;
    margin-top: 10px;
  }

  .full-accordion{
    margin: 5px;
  }

}





</style>
