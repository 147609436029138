<template>
  <div class="home flex flex-col h-full bg-white h-full">

    <Notfall class="z-50" v-if="$store.state.notfall"> </Notfall>

    <div class="body w-full mx-auto bg-white">

      <Navigation> </Navigation>

      <!-- <div v-if="" class="mx-auto my-20 h-screen">

        <p class="text-center"><i class="fas fa-spin text-blue-500 fa-stethoscope spinner-big"></i> </p>

      </div> -->

      <AktuellesDetail class="mb-8"> </AktuellesDetail>

      <FooterVue> </FooterVue>

    </div>

  </div>

</template>

<script>
// @ is an alias to /src
import GridPraxen from '@/components/GridPraxen.vue'
import Navigation from '@/components/Navigation.vue'
import Overview from '@/components/Overview.vue'
import AktuellesDetail from '@/components/AktuellesDetail.vue'
import FooterVue from '@/components/FooterVue.vue'

export default {
  name: 'Aktuelles',
  components: {
    GridPraxen,
    Navigation,
    Overview,
    AktuellesDetail,
    FooterVue
  },

  data() {
    return {

    }
  },

  methods: {

  },
  mounted: function(){

  },

}
</script>

<style scoped>

.spinner-big{
  font-size: 5rem;
  text-align: center !important;
}

</style>
