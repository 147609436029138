<template>
  <div class="home flex flex-col h-full bg-white h-full">

    <Notfall class="z-50" v-if="$store.state.notfall"> </Notfall>

    <div class="body w-full mx-auto bg-white">

      <!-- <Map> </Map> -->

      <Navigation> </Navigation>

      <GridPraxen class="mx-auto w-full lg:w-3/4"> </GridPraxen>

      <Cookie> </Cookie>

      <FooterVue class='w-full text-center p-4 pin-b'> </FooterVue>

      <div @click="notfall()" class="fixed bottom-0 right-0 mx-2 sm:mx-8 mb-2 sm:mb-8 btn text-white bg-red-600 cursor-pointer hover:bg-red-700">
        Notfall
      </div>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import GridPraxen from '@/components/GridPraxen.vue'
import Navigation from '@/components/Navigation.vue'
import Overview from '@/components/Overview.vue'
import Map from '@/components/Map.vue'
import FooterVue from '@/components/FooterVue.vue'
import Cookie from '@/components/Cookie.vue'
import Notfall from '@/components/Notfall.vue'

export default {
  name: 'PraxenOverview',
  components: {
    GridPraxen,
    Navigation,
    Overview,
    Map,
    FooterVue,
    Cookie,
    Notfall
  },

  methods: {
    notfall(){

      this.$store.commit('notfall');

    }
  }

}
</script>
