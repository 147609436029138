<template>
  <div class="gridpraxen">


    <h1 class="w-full md:w-1/2 border-blue-100  p-4 rounded">Aktuelles</h1>

    <div v-if="sortedData.length == 0" class="mx-auto my-20">

      <div v-if="nonews == false" class="">

        <p class="text-center"><i class="fas fa-spin text-blue-500 fa-stethoscope spinner-big"></i> </p>

      </div>

      <div v-else class="">

        <p class="text-center"> Momentan gibt es keine News.</p>

      </div>

      <!-- <h2 class="text-center mt-8"> Hier gibt es momentan nichts anzuzeigen ... </h2> -->

    </div>

    <div class="flex flex-wrap m-2">

      <!-- <div v-for="index in sortedData" class="p-2 flex flex-wrap rounded overflow-hidden outline w-full sm:w-1/2 md:w-1/3"> -->

      <div v-if="done == data.length && index.acf.date_up <= actualDate && index.acf.date_down >= actualDate"  v-for="index in sortedData" class="card p-2 rounded outline w-full sm:w-1/2 md:w-1/3 ">

        <!-- <router-link tag="div" :to="'aktuelles/' + index.id" class=""> -->

        <div class="">

          <div class="container">
            <img v-if="index.acf.image" class="image overflow-hidden border-2 border-b-0 border-blue-500" :src="index.acf.image.sizes.medium_large" alt="Bild Aktuelles">

            <img v-else class="image" src="../assets/keyvisual_placeholder_logo.png" alt="Bild Aktuelles">
          </div>

        </div>

        <div class="px-4">

          <p class="mb-0 mt-4 text-sm"> {{convert(index.acf.date_up)}} </p>

          <p class="hover:underline mt-0" v-html="praxisnames[index.acf.praxis[0].ID]"> </p>

          <h2 class="mt-2" v-html="index.acf.title"> </h2>

          <p class="text-black text-base mb-2" v-html="index.acf.description_short">  </p>

          <p v-if="!weiterlesen.includes(index) && index.acf.description_long" @click="readmore(index)" class="url hover:underline mb-4 text-base" >weiterlesen...</p>

          <p v-if="weiterlesen.includes(index)" class="text-black text-base mb-2" v-html="index.acf.description_long"> </p>

          <p v-if="weiterlesen.includes(index)" @click="readmore(index)" class="url hover:underline mb-4 text-base" >weniger anzeigen...</p>

        </div>

        <!-- </router-link> -->

      </div>

    </div>


  </div>
</template>

<script>
export default {
  name: 'GridAktuelles',
  props: {

  },
  data() {
    return {
      data: [],
      accordeon: [],
      weiterlesen: [],
      praxisnames: [],
      example: "hellothere",
      done: 0,
      actualDate: 0,
      nonews: 0,
    }
  },
  methods: {

    getPraxisname(index) {

      // console.log("hello" + this.praxisnames);

      return this.praxisnames[index];

    },

    convert(old){

      // console.log("nodedate:" + old);

      var monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni",
      "Juli", "August", "September", "Oktober", "November", "Dezember"];

      // console.log(old)

      var yyyy = old.slice(0, 4)
      // console.log("Actual:" + yyyy);
      // console.log("This:" + this.actualYear)

      var mm = old.slice(4, 6)
      var mm = parseInt(mm)

      var dd = old.slice(6, 8)

      if (yyyy == this.actualYear){
        return (dd + ". " + monthNames[mm-1] + " " + yyyy);
      } else {
        return (dd + ". " + monthNames[mm-1] + " " + yyyy);
      }
    },

    readmore(index){

      if (this.weiterlesen.includes(index)){
        var arrayposition = this.weiterlesen.indexOf(index);
        console.log(arrayposition);

        if (arrayposition > -1) {
          this.weiterlesen.splice(arrayposition,1)
        }

      } else {
        this.weiterlesen.push(index);
        // console.log(this.weiterlesen);
      }

    },

    fetchContent(){

      fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/aktuelles/?filter[orderby]=name&order=asc')  .then((result) => {
        return result.json();
      }).then((data) => {

        var i;
        for (i in data) {
          this.data.push(data[i]);


          fetch('https://ärztezentrum.ch/api/wp-json/acf/v3/praxis?per_page=100&filter[p]=' + data[i].acf.praxis[0].ID)  .then((result) => {
            return result.json();
          }).then((data) => {

            // console.log(data[0].id);
            // console.log(data[0].acf.name);

            // this.praxisnames[data[0].id] = data[0].acf.name;

            // this.praxisnames[data[0].id] = "<router-link tag='div' :to=\"'praxis/" + data[0].acf.url + "'\">" + data[0].acf.name + "</router-link>";

            this.praxisnames[data[0].id] = " <a href=\"../praxis/" + data[0].acf.url  + "\"> "+ data[0].acf.name +" </a>"

            // console.log(this.praxisnames[data[0].id]);

            this.done = this.done +1;

          });

        }

        // console.log(this.data);

      });

    },

    compare(a, b) {
      if (a.acf.date_up < b.acf.date_up) {
        return -1;
      }
      if (a.acf.date_up > b.acf.date_up) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

    getdate(){
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();

      // console.log(today = dd + '. ' + monthNames[mm] + ' ' + yyyy);

      this.actualYear = yyyy;
      this.actualDate = yyyy + mm + dd
      console.log(this.actualDate);
    },

    checkfornews(){

      setTimeout(() => this.nonews=true, 2000);

    },

    nodata(){




    }

  },
  mounted: function(){
    this.fetchContent();
    this.getdate();
    this.checkfornews();
  },

  computed: {

    sortedData() {
      // console.log(this.data.slice().sort(this.compare));
      return this.data.slice().sort(this.compare);
    },

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.border-box{
  box-sizing: border-box !important;
}

.mt-auto {
  margin-top: auto;
}

.image-auto {
  width: 100%;
  height: auto;

}

.spinner-big{
  font-size: 5rem;
  text-align: center !important;
}

.container {
  position: relative;

}

.image {
  /* opacity: 1;
  /* display: block; */
  /* transition: .5s ease; */
  /* backface-visibility: hidden; */

}


.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}


.text {
  font-size: 16px;
  padding: 16px 32px;
}


</style>
