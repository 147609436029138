<template>
  <div class="home bg-white h-full">

    <Notfall class="z-50" v-if="$store.state.notfall"> </Notfall>

    <div class="body w-full mx-auto bg-white">

      <Navigation> </Navigation>

      <!-- <div v-if="" class="mx-auto my-20 h-screen">

      <p class="text-center"><i class="fas fa-spin text-blue-500 fa-stethoscope spinner-big"></i> </p>

    </div> -->

    <PraxisDetail class="mb-8"> </PraxisDetail>

    <Cookie> </Cookie>

    <FooterVue> </FooterVue>

    <div @click="notfall()" class="fixed bottom-0 right-0 mx-2 sm:mx-8 mb-2 sm:mb-8 btn text-white bg-red-600 cursor-pointer hover:bg-red-700">
      Notfall
    </div>

  </div>

</div>

</template>

<script>
// @ is an alias to /src
import GridPraxen from '@/components/GridPraxen.vue'
import Navigation from '@/components/Navigation.vue'
import Overview from '@/components/Overview.vue'
import PraxisDetail from '@/components/PraxisDetail.vue'
import FooterVue from '@/components/FooterVue.vue'
import Cookie from '@/components/Cookie.vue'
import Notfall from '@/components/Notfall.vue'

export default {
  name: 'praxis',
  components: {
    GridPraxen,
    Navigation,
    Overview,
    PraxisDetail,
    FooterVue,
    Cookie,
    Notfall
  },

  data() {
    return {

    }
  },

  methods: {

    notfall(){

      this.$store.commit('notfall');

    }

  },
  mounted: function(){

  },

}
</script>

<style scoped>

.spinner-big{
  font-size: 5rem;
  text-align: center !important;
}

</style>
