<template>
  <div class="about">

    <h1 class="ml-2 p-4 mt-8">Über uns</h1>

    <div class="sm:flex mx-6">

      <div class="w-full sm:w-11/12 sm:mr-2 mb-4 ">

        <p class="text-black mb-1">
          Das Ärztezentrum Fellergut ist eine Praxisgemeinschaft mit fünf Hausarztpraxen, einer Kinderarztpraxis, einer Augenarztpraxis, einer Psychotherapiepraxis, einer Physiotherapiepraxis und einer Magendarmpraxis.
        </p>

        <Angebot class="mb-6"> </Angebot>

        <h2 class="mb-1">Arbeiten im Ärztezentrum Fellergut</h2>

        <p v-if="" class="text-black mb-8"> Unsere Ärztinnen, Ärzte und medizinischen Praxisassistentinnen und -assistenten profitieren von regelmässigen internen und externen Fortbildungen. Zudem sind wir Mitglieder in Hausärztenetzwerken und verfügen über Qualitätssicherungszertifizierungen. Wir arbeiten eng mit lokalen Spezialärztinnen und -ärzten zusammen.</p>

      </div>

      <div class="w-1/12">

      </div>

      <div class="w-full sm:w-11/12 sm:mr-2 mb-4 ">

        <img class="w-full shadow-2xl" src="../assets/header_22.jpg" alt="Keyvisual Ärztezentrum">

      </div>


    </div>


  </div>

</template>

<script>

import Angebot from '@/components/Angebot.vue'

export default {
  name: 'About',
  components: {
    Angebot
  },
  props: {

  },
  data() {
    return {
      data: [],
      showmenu: false,
      meetus: false,
      offer: false,
    }
  },
  methods: {

    changeStatus: function () {
      this.meetus = !this.meetus;
    },

    changeOffer: function () {
      this.offer = !this.offer;
    },

  },

  computed: {

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
